export default defineI18nConfig(nuxt => ({
    fallbackLocale: process.env.DEFAULT_LANGUAGE || 'en',
    legacy: false,
    datetimeFormats: {
        en: {
            short: {
                year: 'numeric',
                month: 'long',
                day: '2-digit'
            },
            long: {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }
        },
        de: {
            short: {
                year: 'numeric',
                month: 'long',
                day: '2-digit'
            },
            long: {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }
        }
    }
}))