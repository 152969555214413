
// @ts-nocheck


export const localeCodes =  [
  "en",
  "de"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__var_www_vhosts_4eck_media_de_4eck_media_frontend_lang_en_json" */), cache: true }],
  "de": [{ key: "../lang/de.json", load: () => import("../lang/de.json" /* webpackChunkName: "locale__var_www_vhosts_4eck_media_de_4eck_media_frontend_lang_de_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../config/i18n.config.ts?hash=dcfe1420&config=1" /* webpackChunkName: "__config_i18n_config_ts_dcfe1420" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./config/i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "dir": "ltr",
      "iso": "en-US",
      "files": [
        "lang/en.json"
      ]
    },
    {
      "code": "de",
      "name": "German",
      "dir": "ltr",
      "iso": "de-DE",
      "files": [
        "lang/de.json"
      ]
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "lang/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://4eck-media.de",
  "dynamicRouteParams": true,
  "customRoutes": "config",
  "pages": {
    "page/[slug]": {
      "en": "/[slug]",
      "de": "/[slug]"
    },
    "news/[slug]": {
      "en": "/news/[slug]",
      "de": "/blog/[slug]"
    },
    "competencies/[slug]": {
      "en": "/competencies/[slug]",
      "de": "/kompetenzen/[slug]"
    },
    "references/[slug]": {
      "en": "/references/[slug]",
      "de": "/referenzen/[slug]"
    },
    "job-offers/[slug]": {
      "en": "/job-offers/[slug]",
      "de": "/jobs/[slug]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "dir": "ltr",
    "iso": "en-US",
    "files": [
      {
        "path": "lang/en.json"
      }
    ]
  },
  {
    "code": "de",
    "name": "German",
    "dir": "ltr",
    "iso": "de-DE",
    "files": [
      {
        "path": "lang/de.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
