import { default as callback6tlY4MkD4aMeta } from "/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/auth/[provider]/callback.vue?macro=true";
import { default as _91slug_93a9R7ollnnEMeta } from "/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/competencies/[slug].vue?macro=true";
import { default as index9RKcpdje5hMeta } from "/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/index.vue?macro=true";
import { default as _91slug_93WTg8qmRfmYMeta } from "/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/jobs/[slug].vue?macro=true";
import { default as _91slug_93ZEOjyb9wYeMeta } from "/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/news/[slug].vue?macro=true";
import { default as _91slug_932VD9OiDIlDMeta } from "/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/page/[slug].vue?macro=true";
import { default as _91slug_93GmftShGM6iMeta } from "/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/references/[slug].vue?macro=true";
export default [
  {
    name: "auth-provider-callback___en",
    path: "/en/auth/:provider()/callback",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/auth/[provider]/callback.vue").then(m => m.default || m)
  },
  {
    name: "auth-provider-callback___de",
    path: "/auth/:provider()/callback",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/auth/[provider]/callback.vue").then(m => m.default || m)
  },
  {
    name: "competencies-slug___en",
    path: "/en/competencies/:slug()",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/competencies/[slug].vue").then(m => m.default || m)
  },
  {
    name: "competencies-slug___de",
    path: "/kompetenzen/:slug()",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/competencies/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___en",
    path: "/en/jobs/:slug()",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___de",
    path: "/jobs/:slug()",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news-slug___de",
    path: "/blog/:slug()",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "page-slug___en",
    path: "/en/:slug()",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: "page-slug___de",
    path: "/:slug()",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: "references-slug___en",
    path: "/en/references/:slug()",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/references/[slug].vue").then(m => m.default || m)
  },
  {
    name: "references-slug___de",
    path: "/referenzen/:slug()",
    component: () => import("/var/www/vhosts/4eck-media.de/4eck-media/frontend/pages/references/[slug].vue").then(m => m.default || m)
  }
]